<template>
  <div class="box">
    <h1>Raul Ferreira Fuentes</h1>
    <div>
      <ul>
        <li>
          <a href="https://github.com/Raudius" target="_blank">Github</a>
        </li>
        <li>
          <a href="cv.pdf" target="_blank">CV</a>
        </li>
        <li>
          <a href="https://linkedin.com/in/raul-ff" target="_blank">LinkedIn</a>
        </li>
        <li>
          <a href="mailto:r.ferreirafuentes@gmail.com" target="_blank">Contact</a>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Info',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  text-align: center;
}
ul {
  display: inline;
  list-style-type: none;
  margin: 0 -20px 0 0;
  padding: 0;
}
li {
  display: inline-block;
  padding: 0;
  margin-top: 10px;
  margin-right: 40px;
  text-align: center;
}
a {
  color: #2b747f;
  text-decoration: none;
  font-weight: bolder;
}
.box {
  position: absolute;
  left: 50%;
  width: 550px;
  margin-left: -275px;
  text-align: center;
}

</style>
