<template>
  <Info msg="Welcome to Your Vue.js App"/>
</template>

<script>
import Info from './components/Info.vue'

export default {
  name: 'App',
  components: {
    Info
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&family=Ubuntu:wght@300&display=swap');
#app {
  font-family: Ubuntu , sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}


</style>
